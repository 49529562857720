import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NeuralNetworkBackground from './NeuralNetworkBackground'; // Add this import

import gradientImage1 from '../assets/orangepurpleblue1.png';
import gradientImage2 from '../assets/orangepurpleblue2.png';
import gradientImage3 from '../assets/orangepurpleblue8.png';


const PageWrapper = styled.div`
  background-color: transparent;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  min-height: 100vh;
  position: relative;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  position: fixed; // Make the navbar fixed
  top: 0; // Position it at the top
  left: 0; // Align it to the left
  width: 100%; // Make it span the full width
  background-color: #F8F6EE; // Ensure it has a background color
  z-index: 1000; // Ensure it sits above other elements
`;

const Hero = styled.header`
  text-align: center;
  padding: 4rem 0 0rem;
  position: relative; // Add this
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60vw;
  height: 105%;
  background: linear-gradient(
    to right,
    rgba(248, 246, 238, 0) 0%,
    rgba(248, 246, 238, 1) 10%,
    rgba(248, 246, 238, 1) 90%,
    rgba(248, 246, 238, 0) 100%
  );
  z-index: -1;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  margin-bottom: 2rem;
`;

const FeaturesSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem 0;
`;

const FeatureCard = styled(Link)`
  display: block;
  text-decoration: none;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.15)
  ), url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  padding: 2rem;
  border-radius: 8px;
  text-align: left;
  color: white;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease;
    z-index: 1;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);

    &::before {
      transform: scale(1.05);
    }
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &::after {
    content: ${props => props.showDemo ? '"View Demo →"' : '""'};
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 0.9rem;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    z-index: 3;
  }

  &:hover::after {
    opacity: 1;
  }
`;


const HomePage = () => (
  <PageWrapper>
    <NeuralNetworkBackground /> {/* Add this line */}
    <Container>
      <Navbar>
        <div>pythoneer.ai</div>
      </Navbar>
      <Hero>
        <HeroBackground />
        <HeroContent>
          <Title>
            A <span style={{ color: '#7cacec'  }}>coding agent</span> capable of making codebase-wide changes
          </Title>
          <Subtitle>
            Pythoneer AI is a tool to help developers make codebase-wide changes to their Python projects - giving them more time to focus on more enjoyable and rewarding problems.
          </Subtitle>
          <Subtitle>
            The agent is provided with a codebase and a task, and takes iterative steps to complete it.
            Through the use of tools, the agent is able to open, edit and create files,
            and run tests and python scripts.
          </Subtitle>
          <Subtitle>
            Developed as part of Anthropic's Build with Claude June 2024 contest.
          </Subtitle>
        </HeroContent>
      </Hero>
      <FeaturesSection>
      <FeatureCard to="/py2topy3demo" backgroundImage={gradientImage1} showDemo>
        <h3>Python 2 to 3 Migration</h3>
        <p>Convert legacy Python 2 code to Python 3 while ensure existing functionality is preserved.</p>
      </FeatureCard>
      <FeatureCard to="/pytorchtotensorflowdemo" backgroundImage={gradientImage2} showDemo>
        <h3>PyTorch to TensorFlow Conversion</h3>
      </FeatureCard>
      <FeatureCard backgroundImage={gradientImage3}>
        <h3>TensorFlow to PyTorch Conversion</h3>
      </FeatureCard>
    </FeaturesSection>
    </Container>
  </PageWrapper>
);

export default HomePage;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const PageWrapper = styled.div`
  background-color: #F8F6EE;
  color: #333333;
  font-family: 'Roboto Mono', monospace;
  min-height: 100vh;
  padding: 2rem;
  box-sizing: border-box;
`;

const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding-top: 0rem; // Add padding to the top to account for the Navbar height
`;

const DemoHeader = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const DemoTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const DemoContext = styled.p`
  font-size: 1rem;
  max-width: 800px;
  margin: 0 auto;
`;

const BoxesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 600px 300px auto;
  grid-gap: 2rem;
`;

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  position: fixed; // Make the navbar fixed
  top: 0; // Position it at the top
  left: 0; // Align it to the left
  width: 100%; // Make it span the full width
  background-color: #F8F6EE; // Ensure it has a background color
  z-index: 1000; // Ensure it sits above other elements
`;

const Box = styled.div`
  background-color: #FFFFFF;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #000000; // Black outline
`;

const BoxHeader = styled.div`
  background-color: #F0F0F0;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-bottom: 1px solid #E0E0E0;
`;

const BoxContent = styled.div`
  padding: 1rem;
  overflow-y: auto;
  flex-grow: 1;
`;

const ThoughtsBox = styled(Box)`
  grid-row: 1 / 3;
`;

const FileViewer = styled(Box)`
  font-family: 'Roboto Mono', monospace;
  background-color: #292C34;
`;

const FileViewerContent = styled(BoxContent)`
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Terminal = styled(Box)`
`;

const TerminalHeader = styled(BoxHeader)`
  background-color: #F0F0F0;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-bottom: 1px solid #E0E0E0;
`;

const TerminalContent = styled(BoxContent)`
  background-color: #292C34;
  font-family: 'Roboto Mono', monospace;
  color: #FFFFFF;
  padding: 0.5rem 1rem;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Button = styled.button`
  background-color: #eed9c4;
  color: black;
  padding: 0.5rem 1rem;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 1rem;
  font-family: 'Roboto Mono', monospace;
  transition: border-color 0.3s;

  &:hover {
    border-color: black;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 0rem;
  grid-column: 1 / -1;

`;

const ThoughtsContent = styled(BoxContent)`
  display: flex;
  flex-direction: column;
`;

const ThoughtBubble = styled.div`
  background-color: ${props => props.isLatest ? '#d7bc97' : '#eed9c4'};
  color: ${props => props.isLatest ? 'black' : 'inherit'};
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  align-self: flex-start;
  outline: ${props => props.isLatest ? '2px solid #d7bc97' : 'none'};
  font-weight: ${props => props.isLatest ? 'normal' : 'normal'};
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const ToolUsageMessage = styled.div`
  background-color: #F0F0F0;
  color: ${props => props.isLatest ? 'white' : 'inherit'};
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  align-self: flex-start;
  box-shadow: ${props => props.isLatest ? '0 2px 10px rgba(33, 150, 243, 0.3)' : 'none'};
  font-weight: ${props => props.isLatest ? 'normal' : 'normal'};
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const ReviewCommentBubble = styled.div`
  background-color: #c5d4e8;
  color: #333;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  align-self: flex-end;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  margin: 0;
  background-color: transparent !important;
  font-family: 'Roboto Mono', monospace !important;
`;

const StyledLink = styled(Link)`
  text-decoration: none; // Remove underline
  color: inherit; // Inherit the color from the parent element
`;

const Py2toPy3DemoPage = () => {
    const [trajectory, setTrajectory] = useState([]);
    const [currentStep, setCurrentStep] = useState(-1);
    const [thoughts, setThoughts] = useState([]);
    const [isStreaming, setIsStreaming] = useState(false);
    const [displayedContent, setDisplayedContent] = useState('');
    const [streamingStep, setStreamingStep] = useState(-1);
    const [streamChars, setStreamChars] = useState([]);
    const ThoughtsContentRef = React.useRef(null);

    useEffect(() => {
        fetch('/py2topy3_trajectory.json')
          .then(response => response.json())
          .then(data => {
            setTrajectory(data);
            setThoughts(data.map(step => ({
              thought: step.thought,
              reviewComment: step.review_comment,
              toolName: step.tool_name
            })));
          });
      }, []);

    const scrollToBottom = () => {
        if (ThoughtsContentRef.current) {
        ThoughtsContentRef.current.scrollTop = ThoughtsContentRef.current.scrollHeight;
        }
    };

    const currentData = trajectory[currentStep] || {};

    const handlePrevious = () => {
        setCurrentStep(prev => Math.max(0, prev - 1));
    };

    const handleNext = () => {
      setCurrentStep(prev => {
        const nextStep = Math.min(trajectory.length - 1, prev + 1);
        const nextData = trajectory[nextStep];
        if ((nextData.tool_name === 'create_file' || nextData.tool_name === 'edit_file') && nextStep !== streamingStep) {
          streamContent(nextData.file_viewer_content, nextStep);
        } else if (!isStreaming) {
          setDisplayedContent(nextData.file_viewer_content || '');
        }
        setTimeout(scrollToBottom, 0);
        return nextStep;
      });
    };

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft') {
          handlePrevious();
        } else if (event.key === 'ArrowRight') {
          handleNext();
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [handlePrevious, handleNext]);

    const isPythonFile = (filename) => {
      const result = filename && filename.endsWith('.py');
      console.log(`Is "${filename}" a Python file?`, result);
      return result;
  };

    const streamContent = (content, step) => {
      setIsStreaming(true);
      setStreamingStep(step);
      setDisplayedContent('');
      const chars = content.split('');
      setStreamChars(chars);
    };

    useEffect(() => {
      if (isStreaming && streamChars.length > 0) {
        const timer = setTimeout(() => {
          setDisplayedContent(prev => {
            const newChar = streamChars[0];
            const newContent = prev + newChar;
            setStreamChars(streamChars.slice(1));
            if (streamChars.length === 1) {
              setIsStreaming(false);
            }
            return newContent;
          });
        }, 0.5); // Adjust this value to control streaming speed (lower value for faster streaming)

        return () => clearTimeout(timer);
      }
    }, [isStreaming, streamChars]);

    return (
        <PageWrapper>
          <Navbar>
            <StyledLink to="/">
              <div>pythoneer.ai</div>
            </StyledLink>
          </Navbar>
          <Container>
          <DemoHeader>
              <DemoTitle>Porting Python 2 to Python 3 Demo</DemoTitle>
              <DemoContext>
                This demo showcases the process of autonomously porting a Python 2 codebase to Python 3.
                You'll see the agent's thoughts, the files it's working on, and the terminal output as it
                progresses through the conversion process. Use your arrow keys ⬅️➡️ or the navigation buttons below to step through
                the porting process.
              </DemoContext>
            </DemoHeader>
            <BoxesContainer>
      <ThoughtsBox>
        <BoxHeader>✨ Thoughts</BoxHeader>
        <ThoughtsContent ref={ThoughtsContentRef}>
          {currentStep >= 0 && thoughts.slice(0, currentStep + 1).map((thoughtObj, index) => (
            <React.Fragment key={index}>
              <ThoughtBubble isLatest={index === currentStep}>
                {thoughtObj.thought}
              </ThoughtBubble>
              {thoughtObj.toolName && (
                <ToolUsageMessage>
                  🔨 Used tool: {thoughtObj.toolName}
                </ToolUsageMessage>
              )}
              {thoughtObj.reviewComment && (
                <ReviewCommentBubble>
                  <strong>Review Comment:</strong>
                  <br />
                  {thoughtObj.reviewComment}
                </ReviewCommentBubble>
              )}
            </React.Fragment>
          ))}
        </ThoughtsContent>
      </ThoughtsBox>
      <FileViewer>
        <BoxHeader>🎨 File Editor: {currentStep >= 0 ? currentData.open_file_name : ''}</BoxHeader>
        <FileViewerContent>
          {currentStep >= 0 && (
            isPythonFile(currentData.open_file_name) ? (
              <StyledSyntaxHighlighter language="python" style={vscDarkPlus}>
                {(isStreaming && currentStep === streamingStep) ? displayedContent : currentData.file_viewer_content}
              </StyledSyntaxHighlighter>
            ) : (
              (isStreaming && currentStep === streamingStep) ? displayedContent : currentData.file_viewer_content
            )
          )}
        </FileViewerContent>
      </FileViewer>
      <Terminal>
        <TerminalHeader>&gt; Terminal</TerminalHeader>
        <TerminalContent>{currentStep >= 0 ? currentData.terminal_content : ''}</TerminalContent>
      </Terminal>
      <ButtonContainer>
        <Button onClick={handlePrevious} disabled={currentStep <= -1}>Previous</Button>
        <Button onClick={handleNext} disabled={currentStep === trajectory.length - 1}>Next</Button>
        <span>Step {currentStep + 1} of {trajectory.length}</span>
      </ButtonContainer>
    </BoxesContainer>
    </Container>
  </PageWrapper>
);
}

export default Py2toPy3DemoPage;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import Py2toPy3DemoPage from './components/Py2toPy3DemoPage';
import PyTorchtoTensorFlowDemoPage from './components/PyTorchtoTensorFlowDemoPage';
import GlobalStyle from './GlobalStyle';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/py2topy3demo" element={<Py2toPy3DemoPage />} />
        <Route path="/pytorchtotensorflowdemo" element={<PyTorchtoTensorFlowDemoPage />} />
      </Routes>
    </Router>
  );
}

export default App;